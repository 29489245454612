import { User } from 'react-feather';

import './UserProfile.css';

const UserProfile = () => {
  return (
          <div className="user-profile-icon">
            <button>
              <User width="5vmax" height="5vmax" strokeWidth="0.05vmax" color="#E9D985"/>
            </button>
          </div>
        );
}

export default UserProfile;
