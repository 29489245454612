import CreateNote from './CreateNote.jsx';
import UserProfile from './UserProfile.jsx';
import { useRef } from 'react';

const Main = () => {
  const h1ref = useRef();
  let startTime;
  const handleMouseEnter = (e) => {
    if(window.innerWidth < 480){
      return;
    }
    // e.target.toggleClass("hover");
    // debugger;
    h1ref.current.classList.add("hover");
    h1ref.current.classList.remove("delayhover");
    startTime = new Date();
  };
  const handleMouseExit = (e) => {

    if(window.innerWidth < 480){
      return;
    }
    // e.target.toggleClass("hover");
    // debugger;
    h1ref.current.classList.remove("hover");
    if(((new Date()) - startTime) < 500){
      h1ref.current.classList.add("delayhover")
    }else{
      setTimeout(()=>h1ref.current.classList.add("delayhover"), 500);
    }
    // clearTimeout(TIMER);
    // if(!h1ref.current.classList.contains("delayhover") && TIMER){
    //   h1ref.current.classList.add("delayhover");
    //   clearTimeout(TIMER);
    // }else{
    setTimeout(()=>h1ref.current.classList.add("delayhover"), 500);
    // }
  };
  return <div className="container">
          <UserProfile />
          <h1 className="delayhover" ref={h1ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit}>
            <span class="movable1">T</span><span>he</span> <span class="movable2">N</span><span>ote.</span><span class="movable3">C</span><span>lub</span>
          </h1>
          <CreateNote />
        </div>
}

export default Main;
