import { PlusCircle } from 'react-feather';

import './CreateNote.css';

const CreateNote = () => {
  const handleClick = () => {
    alert("Hi Dea <3");
  }
  return (
          <div className="create-note">
            <button onClick={handleClick}>
              <PlusCircle width="5vmax" height="5vmax" strokeWidth="0.05vmax" color="#E9D985"/>
            </button>
          </div>
        );
}

export default CreateNote;
